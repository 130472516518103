import { ReactSession } from "react-client-session";

import { StyledFlexbox } from "components/styles/SideBySide.styled";
import SettingBlock from "_components/features/profile/settings/SettingBlock";
import CopyToClipboard from "_components/atoms/buttons/CopyToClipboard";
import { centeredComponent } from "_components/function/modifiers/areaPositions";

import { SITE_URL } from "js/data/constants";

const CenteredFlexbox = centeredComponent(StyledFlexbox);

const AffiliateAccountInfo = () => {
  const userId = ReactSession.get("useruid");

  return (
    <SettingBlock $height="100px" title="Affiliate Link">
      <CenteredFlexbox $align="center" $justify="center">
        <p>Copy this link and share it!</p>
        <CopyToClipboard link={`${SITE_URL}?via=${userId}`} />
      </CenteredFlexbox>
    </SettingBlock>
  );
};

export default AffiliateAccountInfo;
