import React, { ReactElement } from "react";

import { StyledSettingBlock } from "../styles/Settings.styled";

type SettingBlock = {
  [key: string]: any;
  $flex?: string;
  title: string;
};

const SettingBlock = ({ children, title, $flex, ...props }: SettingBlock) => {
  return (
    <StyledSettingBlock $flex={$flex} {...props}>
      <h2>{title}</h2>
      <div>{children}</div>
    </StyledSettingBlock>
  );
};

export default SettingBlock;
