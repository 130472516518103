import { css, styled } from "styled-components";

import {
  DashedUnderlineStyle,
  DashedUnderlineVerticleStyle,
} from "_components/atoms/inline/styles/Decorations.styled";

export const StyledSettingsPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  min-width: 300px;
  height: 100%;
  gap: 35px;

  order: 2;
  flex-grow: 2;
`;

type StyledSettingBlock = {
  $flex?: string;
  $height?: string;
  $padding?: string;
};
export const StyledSettingBlock = styled.div<StyledSettingBlock>`
  & > h2 {
    margin: 0 0 20px 0;
    font-size: 2rem;
    display: flex;
    justify-content: center;
  }

  & > div {
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
    background-color: var(--main-background);
    display: block;
    position: relative;
    min-height: ${(props) => props.$height || "400px"};
    height: ${(props) => props.$height};
    width: 100%;

    ${(props) =>
      props.$flex &&
      css<StyledSettingBlock>`
        padding: ${(props) => props.$padding || "0 5%"};
        display: flex;
        gap: 5%;
        align-items: center;
        justify-content: center;
      `}
  }

  @media only screen and (max-width: 1200px) {
    min-width: 450px;
  }
`;

export const StyledSettingItem = styled.div`
  position: relative;
  color: white;
  padding: 10px 0;

  &::after {
    ${DashedUnderlineStyle};
    --invis-col: var(--main-background);
    bottom: 0;
  }
`;

export const StyledSettingItemBtn = styled.img`
  height: 1rem;
  position: absolute;
  right: 0;
  cursor: pointer;
  transition: 0.3s all ease-in-out;

  &:hover {
    filter: brightness(1.3);
  }
`;
