import { useState } from "react";
import styled from "styled-components";

import { StyledHoverFlagBtn } from "./styles/IconButtons.styled";
import { StyledCenteredArea } from "components/styles/common/CenteredArea.styled";
import { StyledFlexbox } from "components/styles/SideBySide.styled";

type CopyToClipboard = {
  link: string;
};

const StyledLink = styled.input`
  color: var(--action-primary);
  font-weight: bold;
  appearance: none;
  border: unset;
  background: unset;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const CopyToClipboard = ({ link }: CopyToClipboard) => {
  const [text, setText] = useState("Copy");

  function copyLinkToClipboard() {
    if (text === "Copied!") return;
    navigator.clipboard.writeText(link);
    setText("Copied!");
    setTimeout(() => {
      setText("Copy");
    }, 500);
  }

  return (
    <StyledHoverFlagBtn $offset="-15%" $enabled={true}>
      <StyledCenteredArea>
        <div className="hover-flag">{text}</div>
        <StyledLink type="text" value={link} onClick={copyLinkToClipboard} />
      </StyledCenteredArea>
    </StyledHoverFlagBtn>
  );
};

export default CopyToClipboard;
